<!--  -->
<template>
  <div class="container">
    <van-swipe :autoplay="3000" indicator-color="white" class="container-swiper">
        <van-swipe-item v-for="(item,index) in info.bannerPics" :key="index">
            <img :src="item.url" alt="">
        </van-swipe-item>
    </van-swipe>
    <!-- 音频 -->
    <div class="player" v-show="info.introductionAudio!=''">
　　　　<div  class="centerPlay" v-if="isPlayAudio" @click="playAudio">
　　　　　　<img src="../../static/play.jpg"/>
　　　　</div>
　　　　<div  class="centerPlay" v-else  @click="playAudio">
　　　　　　<img src="../../static/pause.jpg"/>
　　　　</div>
        <div class="play-box">
          <van-slider v-model="audioTime" @change="sliderChange" />
　　　　</div>
　　</div>
    <div class="video">
　　　　<audio :src="info.introductionAudio" ref="audio" muted @loadeddata="onloadeddata" autoplay></audio>
　　</div> 
    <!-- 景区导览图 -->
    <div class="container-header">
      <p>景区导览图</p>
    </div>
    <img :src="info.panoramaPic" class="panoramaPic">
    <!-- 景区导览图 -->
    <div class="container-header">
      <p>导游导览</p>
    </div>
    <div class="container-imgList">
        <img :src="info.spotPic1" >
        <img :src="info.spotPic2" >
        <img :src="info.spotPic2" >
    </div>
    
    <div v-html="info.introduction" class="htmlInfo rich-text"></div>
  
  </div>
</template>

<script>
import { Swipe, SwipeItem ,Slider } from 'vant';
export default {
    data () {
        return {
            info:{
                bannerPics:[],
                content:{}
            },
            contentId:'',
            topicSymbol:'min-online-info-list',
            openId:'',
            page: 1,
            size:10,
            audiosrc:'',
            isPlayAudio:false,
            audioSeek:0,
            audioTime:0,
            audioDuration: 0,
        };
    },
    components: {
        [Swipe.name]:Swipe,
        [SwipeItem.name]:SwipeItem,
        [Slider.name]:Slider,
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            let postData= {
                topicSymbol: 'min-guide',
                page:this.page,
                size:this.size,
            }
            console.log(postData);
            this.$api.getContentByPage(postData).then(res=>{
                // 设置内容信息，id
                this.info = res.data.data.records[0].content;
                console.log(this.info)
                this.audiosrc= res.data.data.records[0].content.introductionAudio;
                this.isPlayAudio=false;
                this.Timer();     
                this.playAudio();   
            })
        },
        // 音频文件加载完成
        onloadeddata(){
            this.audioDuration = this.$refs['audio'].duration;
            console.log(this.audioDuration)
        },
        //拖动进度条事件
        sliderChange(value) {
            console.log(value)
            //获取进度条百分比
            var value = value;
            this.audioTime =value;
            var duration = this.audioDuration;
            //根据进度条百分比及歌曲总时间，计算拖动位置的时间
            value = parseInt(value * duration / 100);
            //更改状态
            this.audioSeek = value;
            //调用seek方法跳转歌曲时间
            console.log(value)
            this.$refs['audio'].currentTime = value;
            //播放歌曲
            this.$refs['audio'].play();
            this.isPlayAudio = true;
        },
        playAudio(){
            //获取播放状态和当前播放时间
            var isPlayAudio = this.isPlayAudio;
            var seek = this.audioSeek;
            this.$refs['audio'].pause();
            //更改播放状态
            this.isPlayAudio =  !isPlayAudio;

            if (isPlayAudio) {
            //如果在播放则记录播放的时间seek，暂停
            this.audioSeek =  this.$refs['audio'].currentTime ;
            } else {
            //如果在暂停，获取播放时间并继续播放
            // this.$refs['audio'].src = this.data.audiosrc;
            if ( this.$refs['audio'].duration != 0) {
                this.audioDuration= this.$refs['audio'].duration
            }
            //跳转到指定时间播放
            this.$refs['audio'].currentTime = seek;
            this.$refs['audio'].play();     
            };    
        },
        Timer(){
            console.log(this.isPlayAudio)
            //设置一个计步器
            this.timer = setInterval(()=> {
              //当歌曲在播放时执行
              if (this.isPlayAudio == true) {
                  //获取歌曲的播放时间，进度百分比
                  var seek = this.audioSeek;
                  var duration = this.$refs['audio'].duration;
                  var time = this.audioTime;
                  time = parseInt(100 * seek / duration);
                  console.log(duration)
                  //当进度条完成，停止播放，并重设播放时间和进度条
                  if (time >= 100) {
                  console.log('12321313')
                  this.$refs['audio'].pause();
                  this.audioSeek = 0;
                  this.audioTime = 0;
                  this.audioDuration = duration;
                  this.isPlayAudio = false;
                  return false;
                  }
                  //正常播放，更改进度信息，更改播放时间信息
                  this.audioSeek = seek + 1;
                  this.audioTime = time;
                  this.audioDuration = duration;
              };
            }, 1000);
        },
    },
    // 组件销毁
    beforeDestroy(){
      clearInterval(this.timer);
    } ,
    
}

</script>
<style scoped>
.container-swiper{
    width: 10rem;
    height: 5.44rem;
}
.container-swiper img{
    width: 10rem;
    height: 5.44rem;
}
.container-header{
  height: 0.586rem;
  padding: 0.426rem;
  position: relative;
}
.container-header p{
  font-size:0.426rem ;
  color: #222222;
  font-weight: bold;
  text-indent: 0.2rem;
  margin: 0;
}
.container-header p::before{
  content: '';
  width: 0.08rem;
  height: 0.4rem;
  border-radius: 0.053rem;
  background: #00A5FF;
  left: 0.32rem;
  top: 0.53rem;
  position: absolute;
}
.container-img{
  width: 10rem;
  height: 5.17rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
}
.container-img img{
  width: 100%;
  height: 100%;
}
.htmlInfo{
  width: 10rem;
  padding: 0 0.32rem;
  margin-top:0.32rem;  
  box-sizing: border-box;
}

.player{
  width: 10rem;
  height: 1rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
}
.player .centerPlay{
  width: 0.426rem;
  height: 0.426rem; 
}
.centerPlay img{
  width: 0.426rem;
  height: 0.426rem; 
  float: left;
  margin-top: 0.24rem;
}
.play-box{
  width: 8.67rem;
  float: right;
}
.panoramaPic{
    width: 10rem;
    height: 6.05rem;
    padding:0  0.32rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
}
.container-imgList{
    width: 10rem;
    height: 1.92rem;
    box-sizing: border-box;
    padding: 0 0.32rem;
}
.container-imgList img{
    width: 2.93rem;
    height: 1.92rem;
    margin-right: 0.26rem;
}
.container-imgList img:last-child{
    margin-right: 0;
}
</style>